/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h2: "h2"
  }, _provideComponents(), props.components), {HrefLangManager, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-aid-success/how-to-hear-your-best-in-challenging-situations/'], ['en', 'https://www.hear.com/resources/hearing-aid-success/how-to-hear-your-best-in-challenging-situations/'], ['en-US', 'https://www.hear.com/resources/hearing-aid-success/how-to-hear-your-best-in-challenging-situations/'], ['en-CA', 'https://ca.hear.com/resources/hearing-aid-success/how-to-hear-your-best-in-challenging-situations/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "how-to-hear-your-best-in-challenging-situations",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-hear-your-best-in-challenging-situations",
    "aria-label": "how to hear your best in challenging situations permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Hear Your Best in Challenging Situations"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you have hearing loss or not, some situations are more challenging than others to hear well. Noisy restaurants, city streets, and large social gatherings are just a couple of examples. In these situations, you’ll want a complete arsenal of tools to understand as much of the conversation as possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So remember you don’t just hear with your ears… your eyes and brain are critical to understanding what people are saying, too. Now that you can hear better with hearing aids, it’s important not to neglect the role of your eyes and brain to increase your understanding."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Research shows that as much as 80% of communication happens non-verbally. With hearing loss, you’ve probably honed many of these non-verbal skills by taking into consideration posture, gesturing, and lip-reading. To hear better with your eyes…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Look at the person speaking"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Make sure you can see their face"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Take notice of their gestures"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your brain also plays a big part in understanding what’s being said by using context cues and concentrating. Since speech is often redundant, you can fill in the missing words with relative ease using your brainpower. To hear better with your brain…"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Concentrate on what you want to hear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pay attention to the conversation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ask for clarification when you don’t understand"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Try to tune-out background noise with reverse eavesdropping"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reverse-eavesdropping",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reverse-eavesdropping",
    "aria-label": "reverse eavesdropping permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reverse eavesdropping"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you needed hearing aids, do you remember how you used to eavesdrop on conversations? You could magically “tune out” the person sitting right in front of you in order to hear the conversation next to you. You did this with the power of concentration."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Now that you have hearing aids, you’ll start to hear these side conversations again (and some background noise). But whatever you do, don’t adjust your volume, as it’s not going to help. You need to employ what we call “reverse eavesdropping” to concentrate on the conversation in front of you and “tune out” the side conversations or background noise. Just like any other skill, the more you practice, the easier and better you will get at it over time."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But the most important thing is to relax and remember that even people with perfect hearing have trouble understanding conversations in noisy settings. If you have any questions or need additional support to improve your ability to hear in challenging situations — please give us a call at (786) 526-0602 — we’re always here to help."), "\n", React.createElement(LandingPageCta, {
    copy: "Get hearing aids now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
